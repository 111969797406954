import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OdakyuCommon } from 'src/app/common/odakyu-common';
import { SpinnerService } from 'src/app/service/spinner.service';

@Component({
  selector: 'app-luggage-size',
  templateUrl: './luggage-size.component.html',
  styleUrls: ['./luggage-size.component.css']
})
export class LuggageSizeComponent extends OdakyuCommon implements OnInit {

  constructor(private router: Router,  public translateService: TranslateService, private spinner: SpinnerService) {
    super();
  }
  ngOnInit() {
  }

}
