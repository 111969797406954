import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { EnCryptDeCrypt } from '../common/constants';
@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {
  encrypt(value: string): string {
    const jsonString = JSON.stringify(value);
    return CryptoJS.AES.encrypt(jsonString, EnCryptDeCrypt.EnCryptDeCrypt.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    const decryptedBytes = CryptoJS.AES.decrypt(textToDecrypt, EnCryptDeCrypt.EnCryptDeCrypt.trim());
    const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedText);
  }
}
