import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent {

  @Input() labelName: string = "";
  @Input() iconName: string = "";
  @Input() disabled: boolean = false;
  @Input() styleClass: string = "";
  @Output() changeEmitter = new EventEmitter();
  @Output() clickEmitter = new EventEmitter();
  constructor(private cdRef: ChangeDetectorRef) { }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  emitChange($event: any) {
    this.changeEmitter.emit($event);
  }

  emitClick($event: any) {
    this.clickEmitter.emit($event);
  }

}
