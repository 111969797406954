<div
  style="
    background-color: #efeae9;
    height: 4rem;
    position: fixed;
    border-bottom: 3px solid #ff820e;
  "
  class="col-12"
>
  <app-header></app-header>
</div>
<div
  class="col-12 align-items-center justify-content-center flex"
  style="background-color: #fbfff8; min-height: 100vh"
>
  <div class="align-items-center justify-content-center flex">
    <div class="">
      <div
        class="p-error text-053"
        *ngIf="submit && f['email'].errors && f['email'].errors['email']"
      >
        {{ "メールアドレス は、有効なメールアドレス形式で指定してください。" }}
      </div>
      <span class="p-error text-053" *ngIf="emailFail">
        {{ "マスタに登録されていないメールアドレスです。" }}</span
      >
      <div class="col-12 m-1 p-1 mb-1 text-lable">
        <label for="" class="text-coler"
          >本システム登録済みのメールアドレスを入力してください。</label
        >
        <br />
        <label for="" class="text-coler"
          >再設定したパスワードを送信します。</label
        >
      </div>
      <div class="surface-border border-round py-8 px-5 md:px-8 z-1">
        <div class="mb-4">
          <div class="text-900 text-xl font-bold mb-2 text-center"></div>
        </div>
        <form [formGroup]="formGroup">
          <div class="flex flex-column">
            <div class="col-12 m-0 p-0 mb-1">
              <label for="" class="text-coler">メールアドレス</label>
            </div>
            <div class="col-12 m-0 p-0 mb-3">
              <input
                class="col-12 w-15rem"
                type="text"
                formControlName="email"
                name="userName"
                [ngClass]="{
                  'ng-dirty':
                    submit && f['email'].errors && f['email'].errors['required']
                }"
                pInputText
                (keypress)="keypress($event)"
              />
              <div
                class="p-error"
                *ngIf="
                  submit && f['email'].errors && f['email'].errors['required']
                "
              >
                {{ "メールアドレスは必須です。" }}
              </div>
            </div>
            <button
              type="submit"
              style="
                background-color: #ffdab9;
                border: 1px #ff820e;
                box-shadow: 0 0 0 0.1rem #ff820e;
                height: 35px;
              "
              class="p-element w-full p-button p-component"
              tabindex="0"
              (click)="resetPassword()"
            >
              <label
                class="p-button-label font-semibold"
                style="color: #000000; font-size: 22px; line-height: 29.05px"
                >送 信</label
              >
            </button>
            <div class="flex justify-content-center mt-5">
              <a
                (click)="backLogin()"
                class="cursor-pointer text-sm font-semibold"
                style="color: #0066ff; text-decoration: underline"
              >
                ログイン画面へ戻る</a
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
