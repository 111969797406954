import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { delay } from "rxjs";
import { OdakyuCommon } from "src/app/common/odakyu-common";
import { PasswordRequest } from "src/app/dto/request/passwordRequest";
import { PasswordService } from "src/app/service/security/reset.password";
import { SpinnerService } from "src/app/service/spinner.service";
import { ValidatorCommon } from "src/app/service/validator.common";
@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent extends OdakyuCommon implements OnInit {
  public formGroup: FormGroup = new FormGroup({});
  public valuesValidators = ["email"];
  public passwordRequest: PasswordRequest = new PasswordRequest();
  public submit: boolean = false;
  public emailFail: boolean = false;
  constructor(
    private passwordService: PasswordService,
    private spinnerService: SpinnerService,
    private router: Router
  ) {
    super();
  }
  ngOnInit() {
    this.initFormGroup();
    this.spinnerService.requestEnded();
    this.spinnerService.resetSpinner();
  }
  get f() {
    return this.formGroup.controls;
  }

  initFormGroup() {
    this.formGroup = new FormGroup({
      email: new FormControl(),
    });
  }

  resetPassword() {
    this.submit = true;
    ValidatorCommon.setValidatorFormGroup(
      this.formGroup,
      this.valuesValidators
    );
    if (this.formGroup.invalid) {
      return;
    }
    this.passwordRequest = this.formGroup.value;
    this.passwordService
      .resetPassword(this.passwordRequest)
      .pipe(delay(500))
      .subscribe({
        complete: () => {
          setTimeout(() => {
            this.spinnerService.resetSpinner();
            this.router.navigate(["/login"]);
          }, 300);
        },
        error: (err) => {
          this.emailFail = !this.emailFail;
        },
      });
  }

  keypress(event: KeyboardEvent) {
    this.emailFail = false;
  }

  backLogin() {
    this.router.navigate(["/login"]);
  }
}
