import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LoginRequest } from "src/app/dto/request/loginRequest";
import { PasswordRequest } from "src/app/dto/request/passwordRequest";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PasswordService {
  constructor(private http: HttpClient) {}

  resetPassword(passwordRequest: PasswordRequest): Observable<any> {
    return this.http.post(
      environment.apiUrl + "api/reset-password",
      passwordRequest,
      { headers: environment.httpOptions.headers }
    );
  }
}
