import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { OdakyuCommon } from "src/app/common/odakyu-common";
import { LoginRequest } from "src/app/dto/request/loginRequest";
import { EncryptDecryptService } from "src/app/service/encrypt-decrypt-service";
import { AuthService } from "src/app/service/security/login.service";
import { SpinnerService } from "src/app/service/spinner.service";
import { TokenStorageService } from "src/app/service/token-storage.service";
import { ValidatorCommon } from "src/app/service/validator.common";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent extends OdakyuCommon implements OnInit {
  public formGroupLogin: FormGroup = new FormGroup({});
  public valuesValidators = ["email", "password"];
  public loginRequest: LoginRequest = new LoginRequest();
  public loginFail: boolean = false;
  public submit: boolean = false;
  public emailError: boolean = false;
  public passwordError: boolean = false;
  constructor(
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private encryptDecryptService: EncryptDecryptService
  ) {
    super();
  }
  ngOnInit() {
    this.initFormGroup();
    this.spinnerService.requestEnded();
    this.spinnerService.resetSpinner();
    const token = this.tokenStorage.getToken();
    if(token){
      this.router.navigate(['/admin/shipping-list']);
     }else {
      let rememberLogin = this.tokenStorage.getRememberMe();
      if(rememberLogin){
        rememberLogin = rememberLogin.slice(1,-1);
        let user = this.encryptDecryptService.decrypt(rememberLogin);
        if(user&& user.checked){
          this.setRememberMe(user);
        }
      }
     }
    
  }
  get f() {
    return this.formGroupLogin.controls;
  }

  initFormGroup() {
    this.formGroupLogin = new FormGroup({
      email: new FormControl(),
      password: new FormControl(),
      checked: new FormControl(false),
    });
  }

  setRememberMe(user: LoginRequest) {
    this.formGroupLogin = new FormGroup({
      email: new FormControl(user.email),
      password: new FormControl(user.password),
      checked: new FormControl(user.checked),
    });
  }

  login() {
    this.submit = true;
    ValidatorCommon.setValidatorFormGroup(
      this.formGroupLogin,
      this.valuesValidators
    );

    if (this.formGroupLogin.invalid) {
      return;
    }
    this.loginRequest = this.formGroupLogin.value;

    if (this.loginRequest.checked) {
      this.rememberLogin(this.loginRequest);
    }
    this.authService.login(this.loginRequest).subscribe({
      next: (response) => {
        this.apiResponse = response;
      },
      complete: () => {
        if (
          this.apiResponse.status.code === this.httpStatusCode.HTTP_STATUS_200
        ) {
          this.tokenStorage.saveToken(this.apiResponse.result.token);
          this.tokenStorage.saveUserName(this.apiResponse.result.mngUserName);
          this.tokenStorage.saveUserRole(this.apiResponse.result.mngUserRole);
          setTimeout(() => {
            this.spinnerService.resetSpinner();
            this.router.navigate(["/admin/shipping-list"]);
          }, 300);
        } else {
          this.loginFail = !this.loginFail;
          this.emailError = true;
          this.passwordError = true;
        }
      },
      error: (err) => {
        this.loginFail = !this.loginFail;
        this.emailError = true;
        this.passwordError = true;
      },
    });
  }

  resetPassword() {
    this.router.navigate(["/reset-password"]);
  }

  keypress(event: KeyboardEvent) {
    this.loginFail = false;
  }

  keyup(event: KeyboardEvent) {
    this.loginFail = false;
  }

  rememberLogin(object: any) {
    let encrypt = this.encryptDecryptService.encrypt(object);
    this.tokenStorage.saveRememberMe(encrypt);
  }
}
