<div class="grid p-0 col-12 m-0 p-0 justify-content-end">
  <div id="mySidebar" class="col-2 my-0 p-0 "
    style="border-right: 1px solid #000000; position: fixed; top: 4rem; left: 0; height: 100vh;  padding: 10px;">
    <app-sidenav></app-sidenav>
  </div>
  <div class=" col-12 pt-7" id="main">
    <app-header style="position: fixed; top: 0; left: 0; width: 100%; background-color: #EFEAE9
      ;  padding: 10px; border-bottom:3px solid #FF820E; z-index: 99;"></app-header>
  </div>
  <div class="col-10 mx-0 px-0" >
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>