export class Constant {}

export enum HttpStatusCodeConstants {
  HTTP_STATUS_200 = 200,
  HTTP_STATUS_500 = 500,
  HTTP_STATUS_400 = 400,
  HTTP_STATUS_401 = 401,
  HTTP_STATUS_403 = 403,
  HTTP_STATUS_404 = 404,
  HTTP_STATUS_409 = 409,
}
export enum EnCryptDeCrypt {
  EnCryptDeCrypt = "odakuy@123456",
}

export enum PageSize {
  PAGE_SIZE = 20,
  PAGE_LINK_SIZE = 5,
  PAGE_NO = 0
}

export enum TextConstants {
  SHIP_ID = "shipId",
  USAGE_DATE = "usageDate",
  CAREFUL_FLG = "carefulFlg",
  STAY_FACILITY_NAME = "stayFacilityName",
  TEL = "tel",
  SHIPPING_FEE_TOTAL = "shippingFeeTotal",
  DISCOUNT_ID = "discountId",
  SHIPPING_FEE_NAME = "shippingFeeName",
  SHIPPING_ACCEPTED_STATUS = "shippingAcceptedStatus",
  PAY_STATUS = "payStatus",
  CANCEL_FLG = "cancelFlg",
}

export enum Width {
  WIDTH_100 = "100px",
  WIDTH_150 = "150px",
  WIDTH_200 = "200px",
  WIDTH_250 = "250px",
  WIDTH_50 = "50px",
  WIDTH_300 = "300px",
  WIDTH_80 = "80px",
}

export const availFlgOptionConst = [
  {name: "有効", code: 1},
  {name: "無効", code: 0},
]

export const typeDiscountOptionConst = [
  {name: "現在適用中のみ", code: 1},
  {name: "すべて", code: 2},
]
