import { HttpStatusCodeConstants, PageSize } from "./constants";
import { ApiResponse } from "../dto/api-response";


export class OdakyuCommon {
  public httpStatusCode = HttpStatusCodeConstants;
  public apiResponse: ApiResponse = new ApiResponse();
  public searchResponse: any = null;
  public tableColumns: any[] = [];
  pageNo: number = PageSize.PAGE_NO;
  pageSize: number = PageSize.PAGE_SIZE;
  totalRecords: number = 0;
  public currentPage = 0;
  public confirmAction: any;


  showToast(status: any, item: any) {
    let statusCode: any;
    if (status == 0) {
      statusCode = this.httpStatusCode.HTTP_STATUS_200;
    } else {
      statusCode = this.httpStatusCode.HTTP_STATUS_400;
    }
    this.apiResponse = {
      status: statusCode,
      result: item,
    };
  }
}
