import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export class ValidatorCommon {

  static alreadyExists(formGroup: FormGroup): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors | null => {
      return { alreadyExists: true };
    };
  }

  static setValidatorFormGroupAlreadyExists(formGroup: FormGroup, value: string) {
    formGroup['controls'][value].setValidators([this.alreadyExists(formGroup)]);
    formGroup['controls'][value].updateValueAndValidity();
  }

  static checkIsNullEmptyUndefined(keyword: any): boolean {
    if (keyword !== null && keyword !== '' && keyword !== undefined) {
      return true;
    }
    return false;
  }

  static setValidatorFormGroup(formGroup: FormGroup, values: string[]) {
    for (const value of values) {
      if (value === 'email') {
        formGroup.controls[value].setValidators([Validators.required, Validators.email]);
      } else if (value === 'password') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value ==='usageDate'){
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value ==='stayFacilityId'){
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value ==='reserverName'){
        formGroup.controls[value].setValidators([Validators.required]);
      }
      else if(value ==='tel'){
        formGroup.controls[value].setValidators([Validators.required]);
      }
      formGroup.controls[value].updateValueAndValidity();
    }
  }

  static setValidatorFormGroupDiscount(formGroup: FormGroup, values: string[]) {
    for (const value of values) {
      if (value === 'discountName') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value === 'discountUnitFee') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value === 'discountStartDate') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value ==='discountEndDate'){
        formGroup.controls[value].setValidators([Validators.required]);
      }
      formGroup.controls[value].updateValueAndValidity();
    }
  }

  static setValidatorFormGroupInfor(formGroup: FormGroup, values: string[]) {
    for (const value of values) {
      if (value === 'inforTitle') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value === 'infoContents') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value === 'displayStartDate') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value ==='displayEndDate'){
        formGroup.controls[value].setValidators([Validators.required]);
      }
      formGroup.controls[value].updateValueAndValidity();
    }
  }

  static setValidatorForMasterUser(formGroup: FormGroup, values: string[]) {
    for (const value of values) {
      if (value === 'email') {
        formGroup.controls[value].setValidators([Validators.required, Validators.email]);
      } else if (value === 'userName') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value ==='password'){
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value ==='areaName'){
        formGroup.controls[value].setValidators([Validators.required]);
      }
      formGroup.controls[value].updateValueAndValidity();
    }
  }

  static setValidatorFormGroupFacility(formGroup: FormGroup, values: string[]) {
    for (const value of values) {
      if (value === 'stayAreaId') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value === 'stayFacilityName') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value === 'stayFacilityNameEnglish') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value ==='stayFacilityNameKana'){
        formGroup.controls[value].setValidators([Validators.required]);
      }
      formGroup.controls[value].updateValueAndValidity();
    }
  }

  static setValidatorFormGroupCSV(formGroup: FormGroup, values: string[]) {
    for (const value of values) {
      if (value === 'usageDateFrom') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value === 'usageDateTo') {
        formGroup.controls[value].setValidators([Validators.required]);
      } else if(value === 'exportType') {
        formGroup.controls[value].setValidators([Validators.required]);
      }
      formGroup.controls[value].updateValueAndValidity();
    }
  }

  static removeValidatorFormControls(formGroup: FormGroup, values: string[]) {
    for (const value of values) {
      formGroup.controls[value].setValidators([]);
      formGroup.controls[value].updateValueAndValidity();
    }
  }
}
