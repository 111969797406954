// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { HttpHeaders } from "@angular/common/http";

export const environment = {
    production: false,
    apiUrl: 'https://ssl-test.ss-sunsystem.co.jp/',
    // apiUrl: 'http://52.198.95.152:8080/',
    httpOptions: {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'GET, POST, PUT, DELETE',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, X-Custom-Header, Upgrade-Insecure-Requests',
        }),
        params: {}
    },
    PRINTER_HOST: '172.28.135.103',
    CONFIG: {
        SERVICE_NAME: "(テスト)箱根キャリーサービス",
        CORPORATION_NAME: "(テスト)箱根登山バス株式会社",
        CORPORATION_NAME_EN: "(test)Hakone Tozan Bus Co., Ltd",
        CORPORATION_TEL: "(test)0465-35-1201",
        CORPORATION_ADDRESS: "〒250-0003　(test)神奈川県小田原市東町5-33-1",
        CORPORATION_ADDRESS_EN: "(test)5-33-1, Higashi-cho, Odarawa-shi, Kanagawa, 250-0003, JAPAN"
    }
};
