import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';
import { LogoutService } from './security/logout.service';
import { LogoutRequest } from '../dto/logout-request';
import { delay } from 'rxjs';

const TOKEN_KEY = 'auth-token';
const USER_NAME = 'user';
const ROLE = 'role';
const LANGUAGE = 'la';
const REMEMBER_USER = "re";
@Injectable({
    providedIn: 'root',
})
export class TokenStorageService {
    startDate = '';
    endDate = '';
    sub = new EventEmitter();

    constructor(
        private logoutService : LogoutService,
        private router: Router,
        private spinnerService: SpinnerService
    ) { }

    signOut(): void {
        let token = this.getToken();
        let logoutRequest: LogoutRequest = new LogoutRequest();
        logoutRequest.token = token;
        this.logoutService.logout(logoutRequest).pipe(delay(500)).subscribe((data) => {
            this.spinnerService.resetSpinner();
            this.removeItem();
            this.returnLogin();
        },
            (err) => {
                this.removeItem();
                this.returnLogin();
            }
        );
    }

    public saveOldPrice(price: string): void {
        window.localStorage.removeItem('oldPrice');
        window.localStorage.setItem('oldPrice', price);
    }

    public getOldPrice(): string {
        return window.localStorage.getItem('oldPrice') || '0';
    }
    public saveToken(token: string): void {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.setItem(TOKEN_KEY, token);
    }

    public saveUserName(userName: any): void {
        window.localStorage.removeItem(USER_NAME);
        window.localStorage.setItem(USER_NAME, userName);
    }
    public saveUserRole(userRole: any): void {
        window.localStorage.removeItem(ROLE);
        window.localStorage.setItem(ROLE, userRole);
    }

    public getUserName(): string | null {
        return window.localStorage.getItem(USER_NAME);
    }

    public getRole(): string | null {
        return window.localStorage.getItem(ROLE);
    }

    public getToken(): string | null {
        return window.localStorage.getItem(TOKEN_KEY);
    }

    public clearAll() {
        window.localStorage.clear();
    }

    public removeItem() {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(USER_NAME);
    }

    returnLogin() {
        this.router.navigate(['/login']);
    }

    public setLanguage(language: string): any {
        window.localStorage.removeItem(LANGUAGE);
        window.localStorage.setItem(LANGUAGE, language);
    }

    public getRememberMe(): any {
        return window.localStorage.getItem(REMEMBER_USER);
    }

    public saveRememberMe(object: any): void {
        const objectString = JSON.stringify(object);
        window.localStorage.removeItem(REMEMBER_USER);
        window.localStorage.setItem(REMEMBER_USER, objectString);
    }
}
