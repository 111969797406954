<div
  style="
    background-color: #efeae9;
    height: 4rem;
    position: fixed;
    border-bottom: 3px solid #ff820e;
  "
  class="col-12"
>
  <app-header></app-header>
</div>
<div
  class="col-12 align-items-center justify-content-center flex"
  style="background-color: #fbfff8; min-height: 100vh"
>
  <div class="align-items-center justify-content-center flex">
    <div class="">
      <div class="surface-border border-round py-8 px-5 md:px-8 z-1">
        <div class="mb-4">
          <div class="text-900 text-xl font-bold mb-2 text-center"></div>
        </div>
        <form [formGroup]="formGroupLogin">
          <span class="font-medium err text-xs p-error " *ngIf="loginFail">
            {{ "メールアドレスまたはパスワードが違います。" }}</span
          >
          <div class="flex flex-column">
            <div class="col-12 m-0 p-0 mb-1">
              <label for="" class="text-coler">メールアドレス</label>
            </div>
            <div class="col-12 m-0 p-0 mb-3">
              <input
                class="col-12 w-22rem"
                type="text"
                formControlName="email"
                name="userName"
                [ngClass]="{
                  'ng-dirty':
                    submit && f['email'].errors && f['email'].errors['required']
                }"
                pInputText
                (keypress)="keypress($event)"
              />
              <div
                class="p-error size-error"
                *ngIf="
                  submit && f['email'].errors && f['email'].errors['required'] && !loginFail
                "
              >
                {{ "メールアドレスは必須です。" }}
              </div>
              <div
                class="p-error size-error"
                *ngIf="
                  submit && f['email'].errors && f['email'].errors['email'] && !loginFail
                "
              >
                {{ "メールアドレス は、有効なメールアドレス形式で指定してください。" }}
              </div>
            </div>
            <div class="col-12 m-0 p-0 mb-3">
              <div class="col-12 p-0 w-22rem">
                <div class="col-12 m-0 p-0 mb-1">
                  <label for="" class="text-coler">パスワード</label>
                </div>
                <p-password
                  [toggleMask]="true"
                  formControlName="password"
                  [feedback]="false"
                  id="password"
                  styleClass="w-full"
                  name="password"
                  (keyup)="keyup($event)"
                  [ngClass]="{
                    'ng-dirty':
                      submit &&
                      f['password'].errors &&
                      f['password'].errors['required']
                  }"
                ></p-password>
                <div
                  class="p-error"
                  *ngIf="
                    submit &&
                    f['password'].errors &&
                    f['password'].errors['required']&& !loginFail
                  "
                >
                  {{ "パスワードは必須です。" | translate }}
                </div>
              </div>
            </div>
            <button
              type="submit"
              style="
                background-color: #ffdab9;
                border: 1px #ff820e;
                box-shadow: 0 0 0 0.1rem #ff820e;
                height: 45px;
              "
              class="p-element w-full p-button p-component"
              tabindex="0"
              (click)="login()"
            >
              <label
                class="p-button-label font-semibold"
                style="color: #000000; font-size: 24px; line-height: 29.05px"
                >ログイン</label
              >
            </button>
            <div class="flex justify-content-center mt-5">
              <span
                class="font-semibold text-sm"
                style="color: rgba(111, 111, 112, 1); padding-right: 5px"
              >
                パスワードを忘れた方は</span
              >
              <a
                (click)="resetPassword()"
                class="cursor-pointer text-sm font-semibold"
                style="color: #0066ff; text-decoration: underline"
              >
                こちら。</a
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
