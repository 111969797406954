import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OdakyuCommon } from "src/app/common/odakyu-common";
import { SpinnerService } from "src/app/service/spinner.service";


@Component({
  selector: 'app-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.css']
})
export class CustomerInformationComponent extends OdakyuCommon implements OnInit {

  constructor(private router: Router, public translateService: TranslateService, private spinner: SpinnerService) {
    super();
  }
  public visible: boolean = false;
  ngOnInit() {
    var windowWidth = window.innerWidth;
    console.log(windowWidth);

  }

  linkSize() {
    this.router.navigate(['/customer/size']);
  }

  showpoup() {
    this.visible = true
  }

}
