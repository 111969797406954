<div [attr.disabled]="isSidenavEnabled ? null : true">
  <div style="background-color: #fbfff8">
    <ul class="m-0">
      <li
        class="cursor-pointer pl-3 py-3 h-3rem"
        routerLinkActive="active-menu"
        routerLink="/admin/shipping-list"
        *ngIf="hasRole('admin') || hasRole('staff')"
      >
        <a class="custom-link">
          <span class="font-semibold coler-text font-text">配送受付一覧</span>
        </a>
      </li>
      <li
        class="cursor-pointer pl-3 py-3 h-3rem"
        routerLinkActive="active-menu"
        routerLink="/admin/picking-list"
        *ngIf="hasRole('admin') || hasRole('staff')"
      >
        <a class="custom-link">
          <span class="font-semibold coler-text font-text"
            >ピッキングリスト</span
          >
        </a>
      </li>
      <li
        class="cursor-pointer pl-3 py-3 h-3rem"
        routerLinkActive="active-menu"
        routerLink="/admin/export-csv"
        *ngIf="hasRole('admin') || hasRole('staff')"
      >
        <a class="custom-link">
          <span class="font-semibold coler-text font-text">データCSV出力</span>
        </a>
      </li>
      <li
        class="cursor-pointer pl-3 py-3 h-3rem"
        routerLinkActive="active-menu"
        routerLink="/admin/dormitory-area"
        *ngIf="hasRole('admin')"
      >
        <a class="custom-link">
          <span class="font-semibold coler-text font-text">宿泊地区マスタ</span>
        </a>
      </li>
      <li
        class="cursor-pointer pl-3 py-3 h-3rem"
        routerLinkActive="active-menu"
        routerLink="/admin/facility-management"
        *ngIf="hasRole('admin')"
      >
        <a class="custom-link">
          <span class="font-semibold coler-text font-text">宿泊施設マスタ</span>
        </a>
      </li>
      <li
        class="cursor-pointer pl-3 py-3 h-3rem"
        routerLinkActive="active-menu"
        routerLink="/admin/discount"
        *ngIf="hasRole('admin')"
      >
        <a class="custom-link">
          <span class="font-semibold coler-text font-text">値引マスタ</span>
        </a>
      </li>
      <li
      class="cursor-pointer pl-3 py-3 h-3rem"
      routerLinkActive="active-menu"
      routerLink="/admin/info"
      *ngIf="hasRole('admin')"
    >
      <a class="custom-link">
        <span class="font-semibold coler-text font-text">お知らせマスタ</span>
      </a>
    </li>
      <li
        class="cursor-pointer pl-3 py-3 h-3rem"
        routerLinkActive="active-menu"
        routerLink="/admin/user"
        *ngIf="hasRole('admin')"
      >
        <a class="custom-link">
          <span class="font-semibold coler-text font-text">ユーザーマスタ</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="cursor-pointer text-center mt-4">
    <a
      class="font-semibold coler-text font-text boder-0"
      style="color: blue; height: 2rem"
      (click)="logout()"
    >
      ログアウト
    </a>
  </div>
</div>
