import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Calendar } from "primeng/calendar";

@Component({
  selector: "app-date",
  templateUrl: "./date.component.html",
  styleUrls: ["./date.component.css"],
})
export class DateComponent {
  @ViewChild("calendar", { static: false }) calendar: Calendar | undefined;
  @Input() formName: string = '';
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() placeholder: string = "dd/mm/yyyy";
  @Input() timeOnly: boolean = false;
  @Input() showTime: boolean = false;
  @Input() minDate: Date = new Date("1970/01/01");
  @Input() isEndDate: boolean = false;
  @Output() changeDateEmitter = new EventEmitter();
  @Output() onInputEmitter = new EventEmitter();
  @Output() onBlurEmitter = new EventEmitter();
  @Input() stepMinute: number = 1;
  constructor() {}

  get f() {
    return true;
    return this.formGroup.get(this.formName);
  }

  changDate() {
    this.calendar?.el.nativeElement.querySelector("input").focus();
    this.changeDateEmitter.emit();
  }

  onInput() {
    this.onInputEmitter.emit();
  }

  onBlur() {
    this.onBlurEmitter.emit();
  }
}
