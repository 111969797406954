import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../service/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { TokenStorageService } from '../service/token-storage.service';
import { ValidatorCommon } from '../service/validator.common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public userName: string | null = "";
  public code: string = "";
  public languages: any[] = [];
  constructor(private tokenStorage: TokenStorageService,
    private translate: TranslateService, private spinner: SpinnerService
  ) {
  }
  ngOnInit(): void {
    this.userName = this.tokenStorage.getUserName() == null ? "" : this.tokenStorage.getUserName();
  }

  logout() {
    this.tokenStorage.signOut();
    sessionStorage.removeItem('sbm')
  }

  removeDropdownLang() {
    let langDropdownList = document.querySelector(".lang-dropdown-list");
    let selectLang = document.querySelector(".selected-lang");
    if (langDropdownList != null && selectLang != null) {
      langDropdownList.classList.remove("active");
    }
  }

  removeDropdownLogout() {
    let profileDropdownList = document.querySelector(".profile-dropdown-list");
    let btn = document.querySelector(".profile-dropdown-btn");
    if (profileDropdownList != null && btn != null) {
      profileDropdownList.classList.remove("active");
    }
  }

  menuToggle() {
    let langDropdownList = document.querySelector(".lang-dropdown-list");
    let selectLang = document.querySelector(".selected-lang");
    if (langDropdownList != null && selectLang != null) {
      langDropdownList.classList.toggle("active");
    }
    this.removeDropdownLogout();
  }

  // onSelect(lang: any): void {
  //   this.code = lang;
  //   this.removeDropdownLang();
  //   this.tokenStorage.setLanguageDropdown(lang);
  //   setTimeout(() => {
  //     this.spinner.resetSpinner();
  //   }, 500);
  // }
  toggle() {
    let profileDropdownList = document.querySelector(".profile-dropdown-list");
    let btn = document.querySelector(".profile-dropdown-btn");
    if (profileDropdownList != null && btn != null) {
      profileDropdownList.classList.toggle("active");
    }
    this.removeDropdownLang();
  }
}
