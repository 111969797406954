import { Component, OnInit } from "@angular/core";
import { TokenStorageService } from "../service/token-storage.service";
import { SidebarService } from "../service/sidenav/sidebar.service";
@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.css"],
})
export class SidenavComponent implements OnInit {
  public role: string | null = "";
  isSidenavEnabled = true;
  constructor(
    private tokenStorage: TokenStorageService,
    private sidebarService: SidebarService
  ) {}

  ngOnInit() {
    this.role = this.tokenStorage.getRole() == null ? "" : this.tokenStorage.getRole();
    this.sidebarService.isSidenavEnabled$.subscribe((enabled) => {
      this.isSidenavEnabled = enabled;
    });
  }

  logout() {
    this.tokenStorage.signOut();
    sessionStorage.removeItem("sbm");
  }

  hasRole(role: string): boolean {
    if (role === 'admin' && this.role === '0') {
      return true;
    } else if (role === 'staff' && this.role === '1') {
      return true;
    }
    return false;
  }
}
