import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private isSidenavEnabledSubject = new BehaviorSubject<boolean>(true);
  isSidenavEnabled$: Observable<boolean> = this.isSidenavEnabledSubject.asObservable();

  setSidenavEnabled(enabled: boolean): void {
    this.isSidenavEnabledSubject.next(enabled);
  }
}
