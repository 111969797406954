import { Routes, RouterModule } from "@angular/router";
import { CustomerInformationComponent } from "./module/customer/customer-information/customer-information.component";
import { NgModule } from "@angular/core";
import { HomeComponent } from "./home/home.component";
import { LuggageSizeComponent } from "./module/customer/luggage-size/luggage-size.component";
import { LoginComponent } from "./security/login/login.component";
import { ResetPasswordComponent } from "./security/reset-password/reset-password.component";

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '', component: HomeComponent,
    children:
      [
        {
          path: 'admin',
          loadChildren: () => import('./module/admin/admin.module').then(d => d.AdminModule)
        },
      ],
    // canActivate: [AuthGuard]

  },
  {
    path: 'customer', component: CustomerInformationComponent,
    children:
      [
        {
          path: 'customer',
          loadChildren: () => import('./module/customer/customer.module').then(d => d.CustomerModule)
        },
      ],

  },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'customer/size', component: LuggageSizeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
